@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* set primary text color */
/** {*/
/*  !* color: #3b3d4c; *!*/
/*  user-select: none;*/
/*}*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none;
}

.logo-sm {
  height: 2.2em;
  width: auto;
}
.logo-md {
  height: 4em;
  width: auto;
}

.button-bg {
  background: linear-gradient(to right, #f49f02, #f8c509);
}

.main-button:hover {
  background: #f7bc48;
}

.disabled-btn {
  background: grey !important;
}

.disabled-btn p {
  color: white;
}

.button-md {
  padding: 1em 4.5em;
  border-radius: 2em;
  font-size: 0.85em;
  min-width: 12rem;
}
.button-sm {
  padding: 0.6em 2em;
  border-radius: 1.5em;
  font-size: 0.75em;
}

.grey-gradient {
  background: linear-gradient(90deg, #abb4bd 0%, #e5e5e5 100%);
}
.grey-gradient:hover {
  background: linear-gradient(90deg, #abb4bd 0%, #e5e5e5 100%);
}

.container {
  width: 96vw;
  max-width: 1136px;
}

.otp-input {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.section-dog {
  height: 56vh;
}

.absolute-center-x {
  top: 50%;
  transform: translateY(-50%);
}

.header {
  padding: 0.6rem 3.5vw;
}

.side-bar {
  background: rgba(0, 0, 0, 0.05);
  transition: all 500ms;
  height: auto;
}

.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.square-image {
  position: relative;
}
.square-image:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.square-image img {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.three-dot-button {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 2px 8px;
  cursor: pointer;
}

.single-line {
  white-space: nowrap;
  /* display: none; */
}

@media screen and (max-width: 540px) {
  .hide-in-sm {
    display: none;
  }
  .button-md {
    padding: 0.8em 2em;
    border-radius: 2em;
    min-width: 12em;
  }
}

.container.ml-auto.mr-auto.undefined.px-3.md\:mx-auto {
  padding-left: 0 !important;
}

/* Animation */
.flip-animation:hover {
  /* animation: shake 2000ms ease-in-out; */
}

@keyframes shake {
  25% {
    transform: rotateX(90deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  75% {
    transform: rotateX(270deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  width: 25px;
  height: 25px;
}


.checkboxStyle:checked, .checkboxStyle2:checked:after, .checkboxStyle3:checked {
  background-color: #ffffff;
}
.checkboxStyle:checked:after{
  content: '\2714';
  color:#F49F02;
}
.checkboxStyle{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  min-width: 25px !important;
  min-height: 25px !important;
  max-width: 25px !important;
  max-height: 25px !important;
  appearance:none;
  border-radius:10%;
  border: 2px solid #1EAF9C;
  box-shadow: none;
  font-size: 0.8em;
}

.checkboxStyle4{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  min-width: 20px !important;
  min-height: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;
  appearance:none;
  border-radius:10%;
  border: 2px solid #1EAF9C;
  box-shadow: none;
  font-size: 0.8em;
}


.checkboxStyle2:checked:after, .checkboxStyle3:checked:after, .checkboxStyle4:checked:after{
  content: '\2714';
  color:#F49F02;
}
.checkboxStyle2{
  text-align: center;
  margin-top: 60px;
  min-width: 35px !important;
  min-height: 35px !important;
  max-width: 35px !important;
  max-height: 35px !important;
  appearance:none;
  border-radius:10%;
  border: 2px solid #1EAF9C;
  box-shadow: none;
  font-size: 1.3em;
}

.checkboxStyle3{
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 10px;
  min-width: 35px !important;
  min-height: 35px !important;
  max-width: 35px !important;
  max-height: 35px !important;
  appearance:none;
  border-radius:10%;
  border: 2px solid #1EAF9C;
  box-shadow: none;
  font-size: 1.3em;
}


.radioStyle {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #1EAF9C;
  background-color: white;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
.radioStyle:checked {
  background-color: #F49F02;
}